import "./footer.scss";
import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footerWrapper">
        <div className="footerlogocontianer">
          <img
            alt="TIMSLOGO"
            aria-label="NYC Logo"
            src="https://nycdotprojects.info/sites/default/files/inline-images/NYC-Logo-White_0.png"
            title="NYC Logo Image"
            width="80"
            height="28"
            className="footerLogo"
          />
          <span className="copyright"> The City of New York</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
