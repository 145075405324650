import "./search.scss";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import DOMPurify from "dompurify";
import DeleteIcon from "@mui/icons-material/Delete";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const FilterbyId = ({ handleFilter, handleclosefilter }) => {
  const [errors, setErrors] = useState({});

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Validate form fields
    const searchBy = document.getElementById("searchby").value;
    const searchId = document.getElementById("searchid").value;

    const newErrors = {};

    if (!searchBy) {
      newErrors.searchBy = "Please select a search type.";
    }

    if (!searchId) {
      newErrors.searchId = "Please enter a proper search value.";
    }

    // If there are errors, set them and display an alert
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);

      toast.error(
        "Please fix the following errors:\n\n" +
          Object.values(newErrors).join("\n"),
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000, // Close the alert after 3 seconds
          hideProgressBar: false, // Show progress bar
          closeOnClick: true, // Close the alert when clicked
          pauseOnHover: true, // Pause closing when hovered
          draggable: true, // Allow dragging to dismiss
        }
      );
      return;
    }

    // If no errors, proceed with form submission
    const searchData = {
      SearchType: DOMPurify.sanitize(searchBy),
      SearchValue: DOMPurify.sanitize(searchId),
    };

    handleFilter(searchData);
    handleclosefilter();
  };

  return (
    <Dialog open={true} aria-labelledby="responsive-dialog-title">
      <DialogTitle
        sx={{ fontSize: 15, height: 10 }}
        className="responsive-dialog-title"
      >
        TIMS Control Panel
      </DialogTitle>
      <IconButton
        onClick={handleclosefilter}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div>
          <div className="newUser">
            <form className="newUserForm" id="myform">
              <div className="newUserItem">
                <label>Search By:</label>
                <select className="newUserSelect" name="searchby" id="searchby">
                  <option value="CountRequest">Request ID</option>
                  <option value="Site">Count ID</option>
                  <option value="Segment">Segment ID</option>
                  <option value="Node">Node ID</option>
                  <option value="GroupbyId">GroupBy ID</option>
                </select>
              </div>
              <Divider style={{ color: "#006644" }} />
              <div className="newUserItem">
                <label>Search</label>
                <input type="number" id="searchid" placeholder="Search" />
              </div>

              <div className="buttongroupssearch">
                <button className="newUserButton" onClick={handleFormSubmit}>
                  Ok
                </button>
                <button className="newUserButton" onClick={handleclosefilter}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </DialogContent>
      <ToastContainer />
    </Dialog>
  );
};

export default FilterbyId;
