import "./navbar.scss";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { Link as RouterLink } from "react-router-dom";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbarWrapper">
        <div className="leftBar">
          <div>
            <RouterLink className="title">
              <h3>TIMS</h3>
            </RouterLink>
          </div>
        </div>

        <div className="middleBar">
          Hello And Welcome To The NYCDOT Transportation Information Management
          System (TIMS). TIMS Provides Data On The Use Of The City’s Street
          System Including Motor Vehicle, Bicycle And Pedestrian Activity. For
          Guidance On Using The System, Please Click The Help Section Under
          Services.
        </div>

        <div className="rightBar">
          <div className="item">
            <DarkModeIcon className="icon" />
          </div>

          <div className="item">
            <NotificationsNoneIcon />
            <div className="counter">1</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
