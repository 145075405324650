import "./search.scss";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import { getbyAllFilters } from "./../../redux/actions/attributeactions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DOMPurify from "dompurify";
const Search = ({ handlecloseAllfilter }) => {
  const [selectedOption, setSelectedOption] = useState(""); // State to manage the selected option
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  // Function to get the default "From" date (2 years from now)
  const getDefaultFromDate = () => {
    const today = new Date();
    const defaultFromDate = new Date(
      today.getFullYear() - 2,
      today.getMonth(),
      today.getDate()
    );
    return defaultFromDate.toISOString().split("T")[0];
  };

  // Function to get the current date
  const getCurrentDate = () => {
    const today = new Date();
    const futureDate = new Date(
      today.getFullYear(),
      today.getMonth() + 6,
      today.getDate()
    );
    return futureDate.toISOString().split("T")[0];
  };
  const [dateRange, setDateRange] = useState({
    from: getDefaultFromDate(),
    to: getCurrentDate(),
  });
  // Function to handle changes in the "From" date
  const handleFromDateChange = (event) => {
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      from: event.target.value,
    }));
  };

  // Function to handle changes in the "To" date
  const handleToDateChange = (event) => {
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      to: event.target.value,
    }));
  };

  const dispatch = useDispatch();
  const handleDateRangeChange = (event) => {
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);

    const today = new Date();
    let fromDate = "";
    let toDate = "";

    switch (newSelectedOption) {
      case "Lastyearandfuturesixmonth":
        const lastYear = new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate()
        );
        fromDate = lastYear.toISOString().slice(0, 10);
        toDate = futureDate(today);
        break;

      case "Lastcalendaryear":
        const lastYearStart = new Date(today.getFullYear() - 1, 0, 1);
        fromDate = lastYearStart.toISOString().slice(0, 10);
        toDate = futureDate(today);
        break;

      case "Lasttwelvemonths":
        const lastYearDate = new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate()
        );
        fromDate = lastYearDate.toISOString().slice(0, 10);
        toDate = futureDate(today);
        break;

      case "other":
        const previousYearDate = new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate()
        );
        fromDate = previousYearDate.toISOString().slice(0, 10);
        toDate = futureDate(today);
        break;

      case "Lastthreeyears":
        const lastThreeYearDate = new Date(
          today.getFullYear() - 3,
          today.getMonth(),
          today.getDate()
        );
        fromDate = lastThreeYearDate.toISOString().slice(0, 10);
        toDate = futureDate(today);
        break;

      default:
        break;
    }

    setDateRange({ from: fromDate, to: toDate });
  };

  // Function to set the date to 6 months in the future
  const futureDate = (date) => {
    const futureDate = new Date(
      date.getFullYear(),
      date.getMonth() + 6,
      date.getDate()
    );
    return futureDate.toISOString().split("T")[0];
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!dateRange.from || !dateRange.to) {
      // Show an error message or handle the validation error as needed
      toast.error("Please select proper both 'From' and 'To' dates.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000, // Close the alert after 3 seconds
        hideProgressBar: false, // Show progress bar
        closeOnClick: true, // Close the alert when clicked
        pauseOnHover: true, // Pause closing when hovered
        draggable: true, // Allow dragging to dismiss
      });

      return;
    }
    const fromDate = new Date(dateRange.from);
    const toDate = new Date(dateRange.to);
    // Check if the "From" date is greater than the "To" date
    if (fromDate > toDate) {
      // Show an error message or handle the validation error as needed
      toast.error("From date cannot be greater than To date.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000, // Close the alert after 3 seconds
        hideProgressBar: false, // Show progress bar
        closeOnClick: true, // Close the alert when clicked
        pauseOnHover: true, // Pause closing when hovered
        draggable: true, // Allow dragging to dismiss
      });

      return;
    }
    if (toDate < fromDate) {
      // Show an error message or handle the validation error as needed
      toast.error("To date cannot be less than From date.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000, // Close the alert after 3 seconds
        hideProgressBar: false, // Show progress bar
        closeOnClick: true, // Close the alert when clicked
        pauseOnHover: true, // Pause closing when hovered
        draggable: true, // Allow dragging to dismiss
      });

      return;
    }

    const searchFormData = {
      SearchValue: DOMPurify.sanitize(
        document.querySelector("input[type='text']").value
      ),
      CountType: Array.from(
        document.querySelectorAll(".countType option:checked")
      ).map((option) => option.value),
      Borough: Array.from(
        document.querySelectorAll(".borough option:checked")
      ).map((option) => option.value),
      FromDate: dateRange.from, // Include the "From" date in the form data
      ToDate: dateRange.to,
    };

    try {
      // Replace "YOUR_API_ENDPOINT" with the actuadil API endpoint
      dispatch(getbyAllFilters(searchFormData));
      handlecloseAllfilter();
      // Handle the response as needed (e.g., show a success message)
      // Close the dialog after successful submission
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error("Error:", error);
    }
  };
  const handleSelectAllCountType = () => {
    const checkboxes = document.querySelectorAll(".countType option");
    checkboxes.forEach((checkbox) => {
      checkbox.selected = true;
    });
  };

  const handleDeselectAllCountType = () => {
    const checkboxes = document.querySelectorAll(".countType option");
    checkboxes.forEach((checkbox) => {
      checkbox.selected = false;
    });
  };
  const handleSelectAllBoroughs = () => {
    const checkboxes = document.querySelectorAll(".borough option");
    checkboxes.forEach((checkbox) => {
      checkbox.selected = true;
    });
  };

  const handleDeselectAllBoroughs = () => {
    const checkboxes = document.querySelectorAll(".borough option");
    checkboxes.forEach((checkbox) => {
      checkbox.selected = false;
    });
  };

  return (
    <div>
      <Dialog open={true} aria-labelledby="responsive-dialog-title">
        <DialogTitle
          sx={{ fontSize: 15, height: 10 }}
          className="responsive-dialog-title"
        >
          TIMS Control Panel
        </DialogTitle>
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon onClick={handlecloseAllfilter} />
        </IconButton>
        <DialogContent dividers>
          <div>
            <div className="newUser">
              <form className="newUserForm">
                <Accordion
                  expanded={expandedAccordion === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Search</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="newUserItem">
                      <input type="text" placeholder="Search" />
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expandedAccordion === "panel2"}
                  onChange={handleAccordionChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography fontSize={15}>Count Types:</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="newUserItem">
                      <select
                        className="countType"
                        multiple
                        name="CountType"
                        id="CountType"

                        // Add onChange handler if needed
                      >
                        <option selected value="ATR">
                          ATR
                        </option>
                        <option selected value="Bicycle">
                          Bicycle
                        </option>
                        <option selected value="Classification">
                          Classification
                        </option>
                        <option value="GPS Speed">GPS Speed</option>
                        <option selected value="Pedestrian">
                          Pedestrian
                        </option>
                        <option selected value="Spot Speed">
                          Spot Speed
                        </option>
                        <option selected value="Turning Movement">
                          TurningMovement
                        </option>
                      </select>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expandedAccordion === "panel3"}
                  onChange={handleAccordionChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>Borough:</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="newUserItem">
                      <select
                        className="borough"
                        multiple
                        name="Borough"
                        id="Borough"
                      >
                        <option value="Bronx">Bronx</option>
                        <option value="Brooklyn">Brooklyn</option>
                        <option value="Manhattan">Manhattan</option>
                        <option value="Queens">Queens</option>
                        <option value="StatenIsland">Staten Island</option>
                      </select>
                      <div className="button-group">
                        <Button onClick={handleSelectAllBoroughs}>
                          Select All
                        </Button>
                        <Button onClick={handleDeselectAllBoroughs}>
                          Deselect All
                        </Button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expandedAccordion === "panel4"}
                  onChange={handleAccordionChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>Date Range:</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="newUserItem">
                      <select
                        className="dateRange"
                        id="edit-date-range-type"
                        onChange={handleDateRangeChange}
                        value={selectedOption}
                      >
                        <option value="Lastyearandfuturesixmonth">
                          Last Year and Future Six Months
                        </option>
                        <option value="Lastcalendaryear">
                          Last Calendar Year
                        </option>
                        <option value="Lasttwelvemonths">
                          Last Twelve Months
                        </option>
                        <option value="other">Other</option>
                        <option value="Lastthreeyears">Last Three Years</option>
                      </select>
                    </div>
                    <div className="dategroup">
                      <div className="newUserItem">
                        <label>From</label>
                        <input
                          id="edit-from"
                          type="date"
                          value={dateRange.from}
                          onChange={handleFromDateChange}
                          style={{ fontFamily: "arial" }}
                        />
                      </div>
                      <div className="newUserItem">
                        <label>To</label>
                        <input
                          id="edit-to"
                          type="date"
                          onChange={handleToDateChange}
                          value={dateRange.to}
                          style={{ fontFamily: "arial" }}
                        />
                      </div>
                    </div>{" "}
                  </AccordionDetails>
                </Accordion>
                <div className="buttongroupssearch">
                  <button className="newUserButton" onClick={handleFormSubmit}>
                    Ok
                  </button>

                  <button
                    className="newUserButtonCancel"
                    onClick={handlecloseAllfilter}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
        <ToastContainer />
      </Dialog>
    </div>
  );
};

export default Search;
