// action - state management
import { GET_REQUEST_BY_ID } from "../actions/requestdetailtypes";
export const initialState = {
  requestId: [],
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const RequestDetailByIdreducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUEST_BY_ID:
      return {
        ...state,
        requestId: action.payload,
      };
    default:
      return state;
  }
};

export default RequestDetailByIdreducer;
