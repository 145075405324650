import { useParams } from "react-router-dom";
import { getRequestDetailsByID } from "./../../redux/actions/requestdetailsaction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Requestlist from "./Requestlist";
import RequestMap from "./RequestMap";
import "./requestdata.scss";
const Requestdata = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const requestDetailsData = useSelector(
    (state) => state.requestdetailbyidreducer.requestId
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getRequestDetailsByID(id));
  }, [id]);
  return (
    <div className="requestdata">
      {requestDetailsData && requestDetailsData.length !== 0 && (
        <Requestlist data={requestDetailsData} />
      )}
      {requestDetailsData && requestDetailsData.length !== 0 && (
        <RequestMap data={requestDetailsData} />
      )}
    </div>
  );
};

export default Requestdata;
