import React, { useState } from "react";
import Disclaimer_new from "../../Assets/Images/disclaimer.jpg";
import "./disclaimer.scss";
import { Link as RouterLink } from "react-router-dom";
// You can create a CSS file for styling

const Disclaimernew = ({ handledisclaimer }) => {
  return (
    <div className="disclaimer-page">
      <div className="content-container">
        <img className="center-image" src={Disclaimer_new} alt="Disclaimer" />
        <div className="disclaimertext">
          The information provided here was originally compiled by the New York
          Department of Transportation (DOT) and other New York City entities
          for governmental purposes. DOT and the City of New York make no
          representation as to the accuracy or usefulness of the information
          provided by this application or the information's suitability for any
          purpose and disclaim any liability for omissions or errors that may be
          contained there in.
          <div className="disclaimeraccept">
            <RouterLink className="disclaimerbutton" to="/homepage">
              <h3>Accept</h3>
            </RouterLink>
            <RouterLink
              className="disclaimerbutton"
              to="https://www.nyc.gov/html/dot/html/home/home.shtml"
            >
              <h3>Cancel</h3>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimernew;
