// action - state management
import { GET_COUNT_REQUEST_ID } from "./../actions/countrequesttypes";
export const initialState = {
  countId: "",
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const CountRequestByIDreducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNT_REQUEST_ID:
      return {
        ...state,
        countId: action.payload,
      };
    default:
      return state;
  }
};

export default CountRequestByIDreducer;
