import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./requestlist.scss";

const Requestlist = ({ data }) => {
  return (
    <div className="requestlist">
      <TableContainer component={Paper}>
        <Table className="requestdatalist">
          <TableHead
            style={{ backgroundColor: "lightgrey", height: 15, fontSize: 12 }}
          >
            <TableRow
              style={{ backgroundColor: "lightgrey", height: 15, fontSize: 12 }}
            >
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Request ID</TableCell>
              <TableCell>{data[0].countRequestId} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Count Type</TableCell>
              <TableCell>{data[0].countType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Count ID</TableCell>
              <TableCell>{data[0].siteId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Interval</TableCell>
              <TableCell>{data[0].interval} mts</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Location</TableCell>
              <TableCell>{data[0].listCount}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Requestlist;
