// action - state management
import { SET_CUSTOM_ERROR } from "../actions/errortypes";
export const initialState = {
  errormessages: "",
  error: false,
};

const Errorreducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOM_ERROR:
      return {
        ...state,
        errormessages: action.payload.errormessage,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default Errorreducer;
