import axios from "axios";
import {
  GET_ATTRIBUTE_DATA,
  GET_ATTRIBUTE_DATA_BY_ID,
  ENABLE_FILTERBYID,
  DISABLE_FILTERBYID,
  ENABLE_ALLFILTER,
  GET_ATTRIBUTE_BY_ALL_FILTERS,
  UPDATE_MAP,
  FIND_LOCATION_POINTS,
  DATA_NOT_FOUND,
} from "./attributetypes";
import { customerrors } from "./erroractions";

export const getAttributesData = () => async (dispatch, getState) => {
  try {
    const token = localStorage.getItem("jwt");
    const tokenWithoutQuotes = token.replace(/"/g, "");
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Attributes/GetCountRequestAttributes`,
      {},
      {
        headers: {
          Authorization: `Bearer ${tokenWithoutQuotes}`,
        },
      }
    );
    if (response.data.length != null) {
      if (response.data !== "No data found") {
        localStorage.setItem("countdata", JSON.stringify(response.data));

        // If response.data exists and meets your condition
        dispatch({
          type: GET_ATTRIBUTE_DATA,
          payload: response.data,
        });
      } else {
        dispatch(
          customerrors(
            "Oops! Something went wrong! Help us improve your experience by sending an error report",
            true
          )
        );
      }
    }
  } catch (error) {
    dispatch(
      customerrors(
        "Oops! Something went wrong! Help us improve your experience by sending an error report",
        true
      )
    );
  }
};
export const updategetAttributesData = (items) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ATTRIBUTE_DATA,
      payload: items,
    });
  } catch (error) {
    dispatch(
      customerrors(
        "Oops! Something went wrong! Help us improve your experience by sending an error report",
        true
      )
    );
  }
};
export const getbyAllFilters = (searchFormData) => async (dispatch) => {
  try {
    const token = localStorage.getItem("jwt");
    const tokenWithoutQuotes = token.replace(/"/g, "");

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Attributes/SearchFilter`,
      searchFormData,
      {
        headers: { Authorization: `Bearer ${tokenWithoutQuotes}` },
      }
    );

    if (response.status === 200 && response.data && response.data.length > 0) {
      // If response.data exists and meets your condition
      dispatch({
        type: GET_ATTRIBUTE_BY_ALL_FILTERS,
        payload: response.data,
      });
    } else {
      if (response.status === 204) {
        var errormessage = "Data Not Found.";
        var errorcode = true;
        try {
          dispatch({
            type: DATA_NOT_FOUND,
            payload: { errormessage, errorcode },
          });
        } catch (error) {}
      }
      // You can dispatch another action or handle it as needed
    }
  } catch (error) {
    if (error.response.status === 400) {
      var errormessage = error.response.data;
      var errorcode = true;

      dispatch({
        type: DATA_NOT_FOUND,
        payload: { errormessage, errorcode },
      });
    }
  }
};
export const getSearchById = (searchData) => async (dispatch) => {
  try {
    const token = localStorage.getItem("jwt");
    const tokenWithoutQuotes = token.replace(/"/g, "");
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Attributes/SearchFilterByID`,
      searchData,
      {
        headers: { Authorization: `Bearer ${tokenWithoutQuotes}` },
      }
    );

    if (response.data && response.data.length > 0) {
      dispatch({
        type: GET_ATTRIBUTE_DATA_BY_ID,
        payload: response.data,
      });
    } else {
      if (response.status === 204) {
        var errormessage = "Data Not Found.";
        var errorcode = true;

        dispatch({
          type: DATA_NOT_FOUND,
          payload: { errormessage, errorcode },
        });
      }
    }
  } catch (error) {
    if (error.response.status === 400) {
      var errormessage = error.response.data;
      var errorcode = true;

      dispatch({
        type: DATA_NOT_FOUND,
        payload: { errormessage, errorcode },
      });
    }
  }
};
export const enablefilterbyid = (enable, errorcode) => async (dispatch) => {
  dispatch({
    type: ENABLE_FILTERBYID,
    payload: { enable, errorcode },
  });
};
export const enableallfilter = (enable, errorcode) => async (dispatch) => {
  dispatch({
    type: ENABLE_ALLFILTER,
    payload: { enable, errorcode },
  });
};

export const disablefilterbyid = (disable) => async (dispatch) => {
  dispatch({
    type: DISABLE_FILTERBYID,
    payload: disable,
  });
};
export const initialMapData = (disable, zoomindata) => async (dispatch) => {
  dispatch({
    type: UPDATE_MAP,
    payload: { disable, zoomindata },
  });
};
export const findLocationPoints = (x, y, zoomindata) => async (dispatch) => {
  dispatch({
    type: FIND_LOCATION_POINTS,
    payload: { x, y, zoomindata },
  });
};
