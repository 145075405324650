// action - state management
import {
  ENABLE_FILTERBYID,
  GET_ATTRIBUTE_DATA,
  GET_ATTRIBUTE_DATA_BY_ID,
  DISABLE_FILTERBYID,
  ENABLE_ALLFILTER,
  GET_ATTRIBUTE_BY_ALL_FILTERS,
  UPDATE_MAP,
  FIND_LOCATION_POINTS,
  DATA_NOT_FOUND,
  DATA_NOT_FOUND1,
} from "../actions/attributetypes";
export const initialState = {
  items: [],
  filterbyidmodal: false,
  allfitermodal: false,
  initialmap: true,
  x: 0.0,
  y: 0.0,
  zoomindata: false,
  errorcode: false,
  errormessage: "",
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const Attributereducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ATTRIBUTE_DATA:
      return {
        ...state,
        items: action.payload,
      };
    case GET_ATTRIBUTE_DATA_BY_ID:
      return {
        ...state,
        items: action.payload,
      };
    case GET_ATTRIBUTE_BY_ALL_FILTERS:
      return {
        ...state,
        items: action.payload,
      };
    case ENABLE_FILTERBYID:
      return {
        ...state,
        filterbyidmodal: action.payload.enable,
        errorcode: action.payload.errorcode,
      };
    case ENABLE_ALLFILTER:
      return {
        ...state,
        allfitermodal: action.payload.enable,
        errorcode: action.payload.errorcode,
      };
    case DISABLE_FILTERBYID:
      return {
        ...state,
        filterbyidmodal: action.payload,
      };
    case UPDATE_MAP:
      return {
        ...state,
        initialmap: action.payload.disable,
        zoomindata: action.payload.zoomindata,
      };
    case FIND_LOCATION_POINTS:
      return {
        ...state,
        x: action.payload.x,
        y: action.payload.y,
        zoomindata: action.payload.zoomindata,
      };
    case DATA_NOT_FOUND:
      return {
        ...state,
        errormessage: action.payload.errormessage,
        errorcode: action.payload.errorcode,
      };
    case DATA_NOT_FOUND1:
      return {
        ...state,
        errorcode: action.payload,
      };
    default:
      return state;
  }
};

export default Attributereducer;
