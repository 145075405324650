import "./sidebar.scss";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Typography } from "@mui/material";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { countTypesUpdates } from "./../../redux/actions/counttypesactions";
import { useDispatch } from "react-redux";
import CountATRImage from "../../Assets/Images/Count_ATR.png";
import Count_Bicycle from "../../Assets/Images/Count_Bicycle.png";
import Count_Classification from "../../Assets/Images/Count_Classification.png";
import Count_Pedestrian from "../../Assets/Images/Count_Pedestrian.png";
import Count_Speed from "../../Assets/Images/Count_Speed.png";
import Count_GPSLogger from "../../Assets/Images/Count_GPSLogger.png";
import Count_TurningMovement from "../../Assets/Images/Count_TurningMovement.png";
import {
  initialMapData,
  getAttributesData,
  updategetAttributesData,
} from "./../../redux/actions/attributeactions";
export default function Sidebar() {
  const dispatch = useDispatch();
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    dispatch(countTypesUpdates(name, checked));
  };
  const handleClick = () => {
    const countData = localStorage.getItem("countdata");
    // Parse the string representation back to an array
    const dataArray = JSON.parse(countData);
    dispatch(updategetAttributesData(dataArray));
  };

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Home</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem active">
              <a
                href="/homepage"
                className="breadcrumbhome"
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "inherit",
                  textDecoration: "none",
                }}
                onClick={handleClick}
              >
                <DashboardIcon className="icon services" />
                <span className="Data">Data</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Count Types</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <FormControlLabel
                className="countLabels"
                label={
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: "rgba(0, 0, 0, 0.616)",
                    }}
                  >
                    ATR
                  </Typography>
                }
                control={
                  <Checkbox
                    onClick={handleCheckboxChange}
                    defaultChecked
                    name="ATR"
                    id="ATR"
                    sx={{
                      "&.Mui-checked": { color: "black" },
                      "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                  ></Checkbox>
                }
              ></FormControlLabel>

              <img alt="ATR" src={CountATRImage} />
            </li>

            <li className="sidebarListItem">
              <FormControlLabel
                label={
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: "rgba(0, 0, 0, 0.616)",
                    }}
                  >
                    Bicycle
                  </Typography>
                }
                control={
                  <Checkbox
                    onClick={handleCheckboxChange}
                    defaultChecked
                    id="Bicycle"
                    sx={{
                      "&.Mui-checked": { color: "black" },
                      "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                    name="Bicycle"
                  ></Checkbox>
                }
              ></FormControlLabel>
              <img alt="Bicycle" src={Count_Bicycle} />
            </li>

            <li className="sidebarListItem">
              <FormControlLabel
                label={
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: "rgba(0, 0, 0, 0.616)",
                    }}
                  >
                    Classifications
                  </Typography>
                }
                control={
                  <Checkbox
                    onClick={handleCheckboxChange}
                    defaultChecked
                    name="Classifications"
                    sx={{
                      "&.Mui-checked": { color: "black" },
                      "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                  ></Checkbox>
                }
              ></FormControlLabel>
              <img alt="classifications" src={Count_Classification} />
            </li>
            <li className="sidebarListItem">
              {" "}
              <FormControlLabel
                label={
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: "rgba(0, 0, 0, 0.616)",
                    }}
                  >
                    Pedestrians
                  </Typography>
                }
                name="Pedestrians"
                control={
                  <Checkbox
                    onClick={handleCheckboxChange}
                    defaultChecked
                    sx={{
                      "&.Mui-checked": { color: "black" },
                      "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                  ></Checkbox>
                }
              ></FormControlLabel>
              <img alt="Pedestrian" src={Count_Pedestrian} />
            </li>
            <li className="sidebarListItem">
              <FormControlLabel
                label={
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: "rgba(0, 0, 0, 0.616)",
                    }}
                  >
                    Spot Speed
                  </Typography>
                }
                control={
                  <Checkbox
                    onClick={handleCheckboxChange}
                    defaultChecked
                    id=" Spot Speed"
                    sx={{
                      "&.Mui-checked": { color: "black" },
                      "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                    name="SpotSpeed"
                  ></Checkbox>
                }
              ></FormControlLabel>
              <img alt="SpotSpeed" src={Count_Speed} />
            </li>
            <li className="sidebarListItem">
              <FormControlLabel
                label={
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: "rgba(0, 0, 0, 0.616)",
                    }}
                  >
                    Turning Movement
                  </Typography>
                }
                control={
                  <Checkbox
                    onClick={handleCheckboxChange}
                    defaultChecked
                    id="Turning Movement"
                    sx={{
                      "&.Mui-checked": { color: "black" },
                      "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                    name="TurningMovement"
                  ></Checkbox>
                }
              ></FormControlLabel>
              <img alt="TurningMovement" src={Count_TurningMovement} />
            </li>

            <li className="sidebarListItem">
              <FormControlLabel
                label={
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: "rgba(0, 0, 0, 0.616)",
                    }}
                  >
                    GPS Speed
                  </Typography>
                }
                control={
                  <Checkbox
                    onClick={handleCheckboxChange}
                    defaultChecked
                    id="GPSSpeed"
                    sx={{
                      "&.Mui-checked": { color: "black" },
                      "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                    name="GPS Speed<"
                  ></Checkbox>
                }
              ></FormControlLabel>
              <img alt="GPS" src={Count_GPSLogger} />
            </li>
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Services</h3>
          <ul className="sidebarList">
            <li className="sidebarListItemMapLayers">
              <RouterLink
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                className="breadcrumbhome"
                to="/help"
              >
                <SettingsSystemDaydreamOutlinedIcon className="icon services" />
                <span className="help">Help</span>
              </RouterLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
