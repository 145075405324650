import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "./countlist.scss";
import DOMPurify from "dompurify";

const Countlist = ({ data }) => {
  const columns = [
    {
      field: "siteId",
      headerName: "Count ID",
      width: 150,
      headerClassName: "custom-header-class",
    },
    {
      field: "filename",
      headerName: "File Name",
      headerClassName: "custom-header-class",
      width: 300,
      renderCell: (params) => {
        const handleDownload = async () => {
          try {
            var token = localStorage.getItem("jwt");
            const tokenWithoutQuotes = token.replace(/"/g, "");
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/Count/DownloadFileCountById`,
              { id: params.row.siteId.toString() },
              { responseType: "blob" },
              {
                headers: { Authorization: `Bearer ${tokenWithoutQuotes}` },
                "Content-Type": "application/json",
              }
            );
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = params.value; // Set the filename
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Error downloading file:", error);
          }
        };

        return (
          <a href="#" onClick={handleDownload}>
            {DOMPurify.sanitize(params.value)}
          </a>
        );
      },
    },

    {
      headerName: "Borough",
      headerClassName: "custom-header-class",
      field: "borough",
      width: 150,
    },
    {
      headerName: "Direction",
      headerClassName: "custom-header-class",
      field: "direction",
      width: 150,
    },
    {
      headerName: "Street 1 ",
      headerClassName: "custom-header-class",
      field: "onLocation",
      width: 300,
    },
    {
      headerName: "Street 2 ",
      headerClassName: "custom-header-class",
      field: "fromLocation",
      width: 300,
    },
    {
      headerName: "Segment Id",
      headerClassName: "custom-header-class",
      field: "segmentId",
      width: 150,
    },
    {
      headerName: "Node Id",
      headerClassName: "custom-header-class",
      field: "nodeId",
      width: 150,
    },
    {
      field: "addedOn",
      headerName: "Count Document AddedOn",
      width: 250,
      headerClassName: "custom-header-class",
      renderCell: (params) => {
        // Assuming countStartDate is a JavaScript Date object
        const dateObject = new Date(params.value);

        // Format the date as MM/DD/YYYY
        const formattedDate =
          `${(dateObject.getMonth() + 1).toString().padStart(2, "0")}/` +
          `${dateObject.getDate().toString().padStart(2, "0")}/` +
          `${dateObject.getFullYear()}`;

        return <div>{formattedDate}</div>;
      },
    },
  ];
  return (
    <div className="countlist">
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row.filename + "" + row.siteId + "" + row.addedOn}
        density="compact"
        hideFooter={true}
      />
    </div>
  );
};

export default Countlist;
