import axios from "axios";
const API_URL = `${process.env.REACT_APP_API_URL}/api/Auth/GetToken`;

const createAuthService = () => {
  const login = () => {
    try {
      return axios.get(API_URL).then((response) => {
        if (response.data.token) {
          localStorage.setItem("jwt", JSON.stringify(response.data.token));
        }
        return response.data;
      });
    } catch (error) {
      // Handle error here
      console.error("Error during login:", error);
      // Re-throwing the error for the caller to handle
    }
  };
  return {
    login,
  };
};

export default createAuthService();
