import axios from "axios";
import { GET_COUNT_REQUEST_ID } from "./countrequesttypes";
import { customerrors } from "./erroractions";
export const getCountRequestByID = (countId) => async (dispatch) => {
  try {
    const token = localStorage.getItem("jwt");
    const tokenWithoutQuotes = token.replace(/"/g, "");
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Count/GetCountById`; // Replace with your API URL
    const params = {
      SiteId: countId,
    };

    axios
      .post(apiUrl, params, {
        headers: { Authorization: `Bearer ${tokenWithoutQuotes}` },
      })
      .then((response) => {
        // Handle the API response here
        dispatch({
          type: GET_COUNT_REQUEST_ID,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(customerrors(error, true));
      });
  } catch (error) {}
};
