import React, { useEffect, useRef } from "react";
import { loadModules } from "esri-loader";
import proj4 from "proj4";
import "./countmap.scss";

const CountMap = ({ data }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/FeatureLayer",
        "esri/Graphic",
        "esri/geometry/Point",
      ],
      { css: true }
    ).then(([Map, MapView, FeatureLayer, Graphic, Point]) => {
      // Create a map

      const map = new Map({
        basemap: "gray-vector", // You can change the basemap type here
      });

      // Create a view
      const view = new MapView({
        container: mapRef.current,
        map: map,
        center: [-73.95, 40.701],
        zoom: 9,
      });

      const hospitalLegend = {
        type: "simple",
        symbol: {
          type: "picture-marker",
          url:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAP5JREFUOI3Vka2qQkEUhb/RQfwJ/iUPXMFkFEwWo1Gwi81HsPkSwryCb2Az3CI2QdFgsyhYFLEdYXAMJ8joEQ96wz2rzOy12R9rsyV/LBkuYKdzVa4rakGHXRc9HpvfyyXS9wEaWamYbq9HfLkEx4H1Gup1G7JaQaEA8zk0GtBsCj0aGQlCPyWMRr06k4F83nsflc16vXTaqxMJmxGyozwql4Ph0PZSqS+AjgPtdrBkgYDnM2w2tlcuQzL5IXC7hWrV9na7L4CfKKTAwwFiMTge/XtSwun0Fih0qWT2SvEznd5dpeyByeT+HwzQi4WZQUT7Jmy1RPHVKkH1/49yA/00QL6AeXlrAAAAAElFTkSuQmCC",
          width: 10,
          height: 10,
        },
      };
      var hospitals = new FeatureLayer({
        url:
          "https://services5.arcgis.com/GfwWNkhOj9bNBqoJ/arcgis/rest/services/Facilities/FeatureServer/0",
        renderer: hospitalLegend,
        definitionExpression: "FACTYPE = 'HOSPITAL'",
        minScale: 50000,
        maxScale: 0,
      });
      map.add(hospitals);

      const schoolLegend = {
        type: "simple",
        symbol: {
          type: "picture-marker",
          url:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAQ5JREFUOI1jYaAyYCFGkXovwx5OMYb2C7EMe6li4I+3DOacXAy7VVrYb/PL/cw4G8ewnyIDOXgZ/sr6MzD++vhT7cl+hj3qLey3eeR/pp2NZThEloEwwMbPwKAUwMD06uxP9duzGPZpL2TwuxrPsI1sA78+YGB4tIvhFwM/wx7FGIbEs+EMr8hy4Y93DCzXZzH8YuBj2M+jzxB/IZDhJS61xLrwC48+gz4+g0gykJWd4ScxhhFtIClg1EAIUKlneMzAySAI4//+zMCh0sHwBa7gC8O7Oy0MckQbyMzJwKEWx8CNJgznX5/M8IskF1IC6GPg388MjPc2M/zFqesPwz+SDLzTyiBCstPwGUgJAADGekq0pSy7owAAAABJRU5ErkJggg==",
          width: 10,
          height: 10,
        },
      };
      var elementaryschool = new FeatureLayer({
        url:
          "https://services5.arcgis.com/GfwWNkhOj9bNBqoJ/arcgis/rest/services/Facilities/FeatureServer/0",
        renderer: schoolLegend,
        definitionExpression: "FACTYPE = 'ELEMENTARY SCHOOL - PUBLIC'",
        minScale: 50000,
        maxScale: 0,
      });
      map.add(elementaryschool);

      var template = {
        // autocasts as new PopupTemplate()
        title: "{CountsType} - {Borough}",
        content: [
          {
            // It is also possible to set the fieldInfos outside of the content
            // directly in the popupTemplate. If no fieldInfos is specifically set
            // in the content, it defaults to whatever may be set within the popupTemplate.
            type: "fields",
            fieldInfos: [
              {
                fieldName: "CountsType",
                label: "Count Type",
              },
              {
                fieldName: "Borough",
                label: "Borough",
              },
              {
                fieldName: "Direction",
                label: "Direction",
              },
            ],
          },
        ],
      };

      const urls =
        "http://localhost:5286/api/Attributes/GetCountRequestAttributes";

      const graphics = [];

      const totalCounts = data;
      let countsposition = [];
      // Using Proj4 for converting  X and Y  points to Latitude and Longitude
      var nad83 =
        "+proj=lcc +lat_1=41.03333333333333 +lat_2=40.66666666666666 +lat_0=40.16666666666666 +lon_0=-74 +x_0=300000.0000000001 +y_0=0 +ellps=GRS80 +datum=NAD83 +to_meter=0.3048006096012192 +no_defs";
      var wgs84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
      for (var i = 0; i < totalCounts.length; i++) {
        countsposition.push(
          proj4(nad83, wgs84, [totalCounts[i].x, totalCounts[i].y])
        );
        const location = new Point({
          type: "point",
          latitude: countsposition[i][1],
          longitude: countsposition[i][0],
        });

        // var requestID ="<a  onClick='myFunction()'>" + totalCounts[i].field_request_id_value + "</a>";

        graphics.push(
          new Graphic({
            geometry: location,
            attributes: {
              OBJECTID: i,
              CountsType: totalCounts[i].countType,
              RequestID: totalCounts[i].countRequestId,
              CountID: totalCounts[i].siteId,
              // StartDate: totalCounts[i].field_countdatestart_value,
              // EndDate: totalCounts[i].field_countdateend_value,
              Borough: totalCounts[i].borough,
              Direction: totalCounts[i].direction,
              SegmentID: totalCounts[i].segmentId,
              NodeID: totalCounts[i].nodeId,
              // Note: totalCounts[i].on_loc + ': ' + totalCounts[i].from_loc + ' - ' + totalCounts[i].to_loc,
              X: countsposition[i][0],
              Y: countsposition[i][1],
            },
          })
        );
      }

      var countlayer = new FeatureLayer({
        title: "Attributes",
        source: graphics,
        objectIdField: "OBJECTID",
        fields: [
          {
            name: "OBJECTID",
            type: "oid",
          },
          {
            name: "CountsType",
            alias: "CountsType",
            type: "string",
          },
          {
            name: "RequestID",
            alias: "RequestID",
            type: "integer",
          },
          {
            name: "CountID",
            alias: "CountID",
            type: "integer",
          },
          {
            name: "Borough",
            alias: "Borough",
            type: "string",
          },
          {
            name: "Direction",
            alias: "Direction",
            type: "string",
          },
          {
            name: "SegmentID",
            alias: "SegmentID",
            type: "integer",
          },
          {
            name: "NodeID",
            alias: "NodeID",
            type: "integer",
          },
        ],
        popupTemplate: template,
        renderer: {
          type: "unique-value",
          field: "CountsType",
          defaultSymbol: {
            type: "simple-fill",
          },
          uniqueValueInfos: [
            {
              value: "ATR",
              symbol: {
                type: "simple-marker",
                // autocasts as new SimpleMarkerSymbol()
                size: 8,
                color: "red",
                outline: {
                  // autocasts as new SimpleLineSymbol()
                  width: 0.5,
                  color: "black",
                },
              },
            },
            {
              value: "Bicycle",
              symbol: {
                type: "simple-marker",
                // autocasts as new SimpleMarkerSymbol()
                size: 8,
                color: "yellow",
                outline: {
                  // autocasts as new SimpleLineSymbol()
                  width: 0.5,
                  color: "black",
                },
              },
            },
            {
              value: "Pedestrian",
              symbol: {
                type: "simple-marker",
                // autocasts as new SimpleMarkerSymbol()
                size: 8,
                color: "blue",
                outline: {
                  // autocasts as new SimpleLineSymbol()
                  width: 0.5,
                  color: "black",
                },
              },
            },
            {
              value: "Classification",
              symbol: {
                type: "simple-marker",
                // autocasts as new SimpleMarkerSymbol()testing
                size: 8,
                color: "#26ED49",
                outline: {
                  // autocasts as new SimpleLineSymbol()
                  width: 0.5,
                  color: "black",
                },
              },
            },
            {
              value: "Turning Movement",
              symbol: {
                type: "simple-marker",
                // autocasts as new SimpleMarkerSymbol()testing
                size: 8,
                color: "#2596be",
                outline: {
                  // autocasts as new SimpleLineSymbol()
                  width: 0.5,
                  color: "black",
                },
              },
            },
            {
              value: "Spot Speed",
              symbol: {
                type: "simple-marker",
                // autocasts as new SimpleMarkerSymbol()testing
                size: 8,
                color: "#BC028D",
                outline: {
                  // autocasts as new SimpleLineSymbol()
                  width: 0.5,
                  color: "black",
                },
              },
            },
          ],
        },
      });

      view.map.add(countlayer);
    });
  }, [data]);
  return (
    <>
      <div ref={mapRef} className="countmap"></div>
    </>
  );
};

export default CountMap;
