// action - state management
import { Hospital, Police, School } from "../actions/maplayerstypes";
export const initialState = {
  hospital: true,
  police: true,
  school: true,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const MapLayersreducer = (state = initialState, action) => {
  switch (action.type) {
    case Hospital:
      return {
        ...state,
        hospital: action.payload,
      };
    case Police:
      return {
        ...state,
        police: action.payload,
      };
    case School:
      return {
        ...state,
        school: action.payload,
      };

    default:
      return state;
  }
};

export default MapLayersreducer;
