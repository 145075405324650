import axios from "axios";
import { GET_REQUEST_BY_ID } from "./requestdetailtypes";
import { customerrors } from "./erroractions";
export const getRequestDetailsByID = (countRequestId) => async (dispatch) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/CountRequest/GetCountRequestById`; // Replace with your API URL
    const requestData = {
      CountRequestId: countRequestId,
    };

    const token = localStorage.getItem("jwt");
    const tokenWithoutQuotes = token.replace(/"/g, "");
    axios
      .post(apiUrl, requestData, {
        headers: { Authorization: `Bearer ${tokenWithoutQuotes}` },
      })
      .then((response) => {
        // Handle the API response here
        dispatch({
          type: GET_REQUEST_BY_ID,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(
          customerrors(
            "Oops! Something went wrong! Help us improve your experience by sending an error report",
            true
          )
        );
      });
  } catch (error) {}
};
