// action - state management
import {
  GET_EXACT_ADDRESS,
  GET_INTERSECTION,
  ENABLE_GEOCODER,
  DISABLE_GEOCODER,
} from "../actions/locationapitypes";
export const initialState = {
  enablegeocoder: false,
  cordinates: {},
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const LocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXACT_ADDRESS:
      return {
        ...state,
        cordinates: action.payload,
      };
    case ENABLE_GEOCODER:
      return {
        ...state,
        enablegeocoder: action.payload,
      };
    case DISABLE_GEOCODER:
      return {
        ...state,
        enablegeocoder: action.payload,
      };
    case GET_INTERSECTION:
      return {
        ...state,
        cordinates: action.payload,
      };

    default:
      return state;
  }
};

export default LocationReducer;
