// action - state management
import {
  ATR,
  Bicycle,
  Pedestrians,
  TurningMovement,
  SpotSpeed,
  Classifications,
} from "../actions/counttypes";
export const initialState = {
  ATRcheckbox: true,
  Bicyclecheckbox: true,
  Pedestrianscheckbox: true,
  TurningMovementcheckbox: true,
  SpotSpeedcheckbox: true,
  Classificationscheckbox: true,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const CountTypesreducer = (state = initialState, action) => {
  switch (action.type) {
    case ATR:
      return {
        ...state,
        ATRcheckbox: action.payload,
      };
    case Bicycle:
      return {
        ...state,
        Bicyclecheckbox: action.payload,
      };
    case Classifications:
      return {
        ...state,
        Classificationscheckbox: action.payload,
      };
    case TurningMovement:
      return {
        ...state,
        TurningMovementcheckbox: action.payload,
      };
    case Pedestrians:
      return {
        ...state,
        Pedestrianscheckbox: action.payload,
      };
    case SpotSpeed:
      return {
        ...state,
        SpotSpeedcheckbox: action.payload,
      };
    default:
      return state;
  }
};

export default CountTypesreducer;
