import { useState } from "react";
import "./datatable.scss";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { findLocationPoints } from "./../../redux/actions/attributeactions";
const Datatable = ({ data }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const handleSelectionModelChange = (selectionModel) => {
    // Use the selectionModel to get selected rows
    const selectedRows = data.filter((row) =>
      selectionModel.includes(row.siteId)
    );
    try {
      if (selectedRows[0].x != null) {
        dispatch(
          findLocationPoints(selectedRows[0].x, selectedRows[0].y, true)
        );
      }
    } catch (error) {}
  };
  const handletabChange = (selectionModel, event) => {
    // Use the selectionModel to get selected rows
    try {
      if (event.key === "Tab") {
        if (selectionModel.row.x != null) {
          dispatch(
            findLocationPoints(selectionModel.row.x, selectionModel.row.y, true)
          );
        }
      }
    } catch (error) {}
  };
  const fontSize = 12;

  return (
    <div className="attributeTable" style={{ width: "98%", height: "50%" }}>
      <div>
        Total Count: <span style={{ color: "black" }}> {data.length}</span>
      </div>

      <DataGrid
        className="datagrid"
        rows={data}
        columns={[
          {
            headerName: "Type",
            headerClassName: "custom-header-class",
            field: "types",
            width: 140,
          },
          {
            headerName: "Sub Type",
            headerClassName: "custom-header-class",
            field: "countType",
            width: 140,
            render: (rowData) => (
              <RouterLink href={`/help`}>{rowData.countType}</RouterLink>
            ),
          },
          {
            headerName: "Count ID",
            field: "siteId",
            headerClassName: "custom-header-class",
            width: 120,
            renderCell: (params) => {
              const handleLinkClick = (e) => {
                // Dispatch the value to update state using Redux
                // dispatch(getCountRequestByID(params.row.siteId));
              };
              return (
                <RouterLink
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center", color: "blue" }}
                  color="inherit"
                  to={`/countdetails/${params.row.siteId}`}
                  onClick={handleLinkClick}
                >
                  {params.row.siteId}
                </RouterLink>
              );
            },
          },

          {
            headerName: "Request ID",
            field: "countRequestId",
            headerClassName: "custom-header-class",
            width: 110,
            renderCell: (params) => {
              const handleLinkClick = (e) => {
                // Dispatch the value to update state using Redux
                // dispatch(getRequestDetailsByID(params.row.countRequestId));
              };
              return (
                <RouterLink
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center", color: "blue" }}
                  color="inherit"
                  to={`/requestdetails/${params.row.countRequestId}`}
                  onClick={handleLinkClick}
                >
                  {params.row.countRequestId}
                </RouterLink>
              );
            },
          },
          {
            headerName: "Start Date",
            headerClassName: "custom-header-class",
            field: "countStartDate",
            width: 170,
            renderCell: (params) => {
              // Assuming countStartDate is a JavaScript Date object
              const dateObject = new Date(params.value);

              // Format the date as MM/DD/YYYY
              const formattedDate =
                `${(dateObject.getMonth() + 1).toString().padStart(2, "0")}/` +
                `${dateObject.getDate().toString().padStart(2, "0")}/` +
                `${dateObject.getFullYear()}`;

              return <div>{formattedDate}</div>;
            },
          },
          {
            headerName: "End Date",
            headerClassName: "custom-header-class",
            field: "countEndDate",
            width: 170,
            renderCell: (params) => {
              // Assuming countStartDate is a JavaScript Date object
              const dateObject = new Date(params.value);

              // Format the date as MM/DD/YYYY
              const formattedDate =
                `${(dateObject.getMonth() + 1).toString().padStart(2, "0")}/` +
                `${dateObject.getDate().toString().padStart(2, "0")}/` +
                `${dateObject.getFullYear()}`;

              return <div>{formattedDate}</div>;
            },
          },

          {
            headerName: "Borough",
            headerClassName: "custom-header-class",
            field: "borough",
            width: 110,
          },
          {
            headerName: "Direction",
            headerClassName: "custom-header-class",
            field: "direction",
            width: 110,
          },
          {
            headerName: "Segment Id",
            headerClassName: "custom-header-class",
            field: "segmentId",
            width: 110,
          },
          {
            headerName: "Node Id",
            headerClassName: "custom-header-class",
            field: "nodeId",
            width: 110,
          },
          {
            headerName: "Notes",
            headerClassName: "custom-header-class",
            field: "note",
            width: 390,
          },
          {
            headerName: "X",
            headerClassName: "custom-header-class",
            field: "x",
            width: 170,
          },
          {
            headerName: "Y",
            headerClassName: "custom-header-class",
            field: "y",
            width: 170,
          },
        ]}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
              debounceMs: 500,
            },
          },
        }}
        sx={{
          boxShadow: 1,
          "& .MuiDataGrid-cell:hover": {
            color: "primary.secondry",
          },
        }}
        getRowId={(row) => row.siteId}
        pageSize={9}
        rowsPerPageOptions={[9]}
        slots={{ toolbar: GridToolbar }}
        density="compact"
        disableDensitySelector
        onRowSelectionModelChange={handleSelectionModelChange}
        onCellKeyDown={handletabChange}
        pagination
        style={{ fontSize: `${fontSize}px` }}
        hideFooter={true}
        paginationMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCount={data.length}
        components={{
          Toolbar: (props) => (
            <>
              <GridToolbar {...props} />
              <label htmlFor="datagridSearch">Search:</label>
              <input
                id="datagridSearch"
                placeholder="Search…"
                type="search"
                className="MuiInputBase-input MuiInput-input MuiInputBase-inputTypeSearch MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd css-c63i49-MuiInputBase-input-MuiInput-input"
              />
            </>
          ),
        }}
        options={{
          disablePrint: true, // Disable print functionality
          csvExport: false, // Exclude CSV export
          excelExport: false,
          localeText: {
            toolbarPrint: "Custom Print", // Change the print button title
          }, // Exclude Excel export
        }}
      />
    </div>
  );
};

export default Datatable;
