import React, { useState, useEffect, Suspense } from "react";
import "./App.css";
import CircularProgress from "@mui/material/CircularProgress";
import AuthService from "./components/AuthToken/AuthToken";
import CustoomError from "./components/customerrors/CustomErrors";
import { customerrors } from "./redux/actions/erroractions";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Help from "./pages/help/Help";
import Disclaimer from "./pages/disclaimer/Disclaimer";
import RequestDetails from "./pages/requestdetails/RequestDetails";
import Homepage from "./pages/homepage/Homepage";
import CountDetails from "./pages/countdetails/CountDetails";
import Disclaimernew from "./pages/disclaimer/Disclaimernew";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

function App() {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const errornetwork = useSelector((state) => state.errorreducer.error);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) {
          const response = await AuthService.login();
          var tokenstorage = localStorage.getItem("jwt");

          if (tokenstorage) {
            setToken(tokenstorage);
          }
        } else {
          const decodedToken = parseJwt(token);

          if (
            decodedToken &&
            decodedToken.exp < Math.floor(Date.now() / 1000)
          ) {
            // Token has expired, refresh it
            try {
              const response = await AuthService.login(); // Replace with your API endpoint
              var tokenstorage = localStorage.getItem("jwt");
              // Update the token in the component state and localStorage
              setToken(tokenstorage);
            } catch (error) {
              // Handle error (e.g., redirect to login)
            }
          }
        }
        await new Promise((resolve) => setTimeout(resolve, 3000));
        setLoading(false);
        setIsAuthenticated(true); // Set authentication status to true
      } catch (errornetwork) {
        dispatch(
          customerrors(
            "Oops! Something went wrong! Help us improve your experience by sending an error report",
            true
          )
        );
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures that this effect runs only once when the component mounts

  useEffect(() => {
    if (token !== "") {
      // Do something when token changes
      // For example, update some state or perform some action
      console.log("Token changed:", token);
    }
  }, [token]); // Dependency array with token ensures that this effect runs when the token changes

  return (
    <Router>
      <div
        className="spinner-overlay"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div className="spinner-container">
          <CircularProgress />
        </div>
      </div>
      <div className="homeContainer">
        <Navbar />
        <div className="content">
          {!errornetwork && <Sidebar />}
          {errornetwork && <CustoomError />}
          {!errornetwork && (
            <Routes>
              {isAuthenticated && (
                <Route exact path="/" element={<Disclaimernew />} />
              )}
              <Route
                path="/homepage"
                element={token ? <Homepage /> : <CustoomError />}
              />
              <Route path="/help" element={<Help />} />

              <Route path="/requestdetails/:id" element={<RequestDetails />} />
              <Route path="/countdetails/:id" element={<CountDetails />} />
              {errornetwork && <Route path="*" element={<CustoomError />} />}
            </Routes>
          )}
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
