export const GET_ATTRIBUTE_DATA = "GET_ATTRIBUTE_DATA";
export const GET_ATTRIBUTE_DATA_BY_ID = "GET_ATTRIBUTE_DATA_BY_ID";
export const ENABLE_FILTERBYID = "ENABLE_FILTERBYID";
export const DISABLE_FILTERBYID = "DISABLE_FILTERBYID";
export const ENABLE_ALLFILTER = "ENABLE_ALLFILTER";
export const GET_ATTRIBUTE_BY_ALL_FILTERS = "GET_ATTRIBUTE_BY_ALL_FILTERS";
export const UPDATE_MAP = "UPDATE_MAP";
export const FIND_LOCATION_POINTS = "FIND_LOCATION_POINTS";
export const DATA_NOT_FOUND = "DATA_NOT_FOUND";
export const DATA_NOT_FOUND1 = "DATA_NOT_FOUND1";
