import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import reducer from "./reducers/combinereducer";

const initialState = {};
const store = createStore(reducer, initialState, applyMiddleware(thunk));
const persister = "TIMS";

export { store, persister };
