import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import "./help.scss";
import DOMPurify from "dompurify";
import CircularProgress from "@mui/material/CircularProgress";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import HomeIcon from "@mui/icons-material/Home";
function FileGrid() {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    var token = localStorage.getItem("jwt");
    const tokenWithoutQuotes = token.replace(/"/g, "");
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/Document/GetHelpdocuments`, {
        headers: { Authorization: `Bearer ${tokenWithoutQuotes}` },
      })
      .then((response) => {
        setFiles(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "File Id",
      headerClassName: "custom-header-class",
      width: 300,
      hide: true,
    },
    {
      field: "fileName",
      headerName: "File Name",
      width: 405,
      headerClassName: "custom-header-class",
      renderCell: (params) => {
        const handleDownload = async () => {
          try {
            var token = localStorage.getItem("jwt");
            const tokenWithoutQuotes = token.replace(/"/g, "");
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/Document/DownloadFile`,
              { id: params.row.id.toString() },
              { responseType: "blob" },
              {
                headers: { Authorization: `Bearer ${tokenWithoutQuotes}` },
                "Content-Type": "application/json",
              }
            );
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = params.value; // Set the filename
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Error downloading file:", error);
          }
        };

        return (
          <a href="#" onClick={handleDownload}>
            {DOMPurify.sanitize(params.value)}
          </a>
        );
      },
    },
    {
      field: "addedOn",
      headerName: "Added On",
      headerClassName: "custom-header-class",
      width: 300,
      renderCell: (params) => {
        // Assuming countStartDate is a JavaScript Date object
        const dateObject = new Date(params.value);

        // Format the date as MM/DD/YYYY
        const formattedDate =
          `${(dateObject.getMonth() + 1).toString().padStart(2, "0")}/` +
          `${dateObject.getDate().toString().padStart(2, "0")}/` +
          `${dateObject.getFullYear()}`;

        return <div>{formattedDate}</div>;
      },
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "custom-header-class",
      width: 930,
    },
  ];

  return (
    <div className="helpcontainer">
      <Breadcrumbs
        style={{ marginBottom: 5, marginTop: 10 }}
        aria-label="breadcrumb"
      >
        {[
          { label: "Home", icon: <HomeIcon className="homebreadcrumb" /> },
          { label: "Files", icon: <FileCopyIcon className="homebreadcrumb" /> },
        ].map(({ label, icon }, index) => (
          <RouterLink
            key={index}
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            className="breadcrumbhome"
            to={index === 0 ? "/homepage" : "/help"}
          >
            {icon && React.cloneElement(icon, { sx: { mr: 0.1 } })}
            <span style={{ fontSize: 13 }}>{label}</span>
          </RouterLink>
        ))}
      </Breadcrumbs>
      {files.length > 0 ? (
        <DataGrid
          rows={files}
          columns={columns}
          getRowId={(row) => row.fileName}
          columnVisibilityModel={{
            id: false,
          }}
          density="compact"
        />
      ) : (
        <div className="spinner-overlay" style={{ display: "flex" }}>
          <div className="spinner-container">
            <CircularProgress />
          </div>
        </div>
      )}
    </div>
  );
}

export default FileGrid;
