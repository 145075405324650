import "./countdetails.scss";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import Datatable from "../../components/datatable/Datatable";
import { getAttributesData } from "./../../redux/actions/attributeactions";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import CountData from "./../../components/countdata/CountData";
import TrafficIcon from "@mui/icons-material/Traffic";
const CountDetails = () => {
  const dispatch = useDispatch();
  const mapData = useSelector((state) => state.attributereducer.items);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // dispatch(getAttributesData());
  }, []);
  return (
    <div className="countDetails">
      <Breadcrumbs style={{}} aria-label="breadcrumb">
        {[
          { label: "Home", icon: <HomeIcon className="homebreadcrumb" /> },
          {
            label: "Count Details",
            icon: <TrafficIcon className="homebreadcrumb" />,
          },
        ].map(({ label, icon }, index) => (
          <RouterLink
            key={index}
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            className="breadcrumbhome"
            to={index === 0 ? "/homepage" : "/homepage"}
          >
            {icon && React.cloneElement(icon, { sx: { mr: 0.3 } })}
            <span style={{ fontSize: 13 }}>{label}</span>
          </RouterLink>
        ))}
      </Breadcrumbs>

      <CountData />
      <Datatable data={mapData} />
    </div>
  );
};

export default CountDetails;
