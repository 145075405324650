import { useParams } from "react-router-dom";
import { getCountRequestByID } from "./../../redux/actions/countrequestaction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Countlist from "./Countlist";
import CountMap from "./CountMap";
import "./countdata.scss";
const CountData = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const countDetailsData = useSelector(
    (state) => state.countrequestbyidreducer.countId
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getCountRequestByID(id));
  }, [id]);
  return (
    <div className="countdata">
      {countDetailsData && countDetailsData.length !== 0 && (
        <Countlist data={countDetailsData} />
      )}
      {countDetailsData && countDetailsData.length !== 0 && (
        <CountMap data={countDetailsData} />
      )}
    </div>
  );
};

export default CountData;
