import React, { useEffect, useRef } from "react";
import { loadModules } from "esri-loader";
import proj4 from "proj4";
import "./datamap.scss";
import { useDispatch, useSelector } from "react-redux";

import { initialMapData } from "./../../redux/actions/attributeactions";
import { updategetAttributesData } from "./../../redux/actions/attributeactions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DATA_NOT_FOUND1 } from "./../../redux/actions/attributetypes";

const DataMap = ({ data }) => {
  const mapRef = useRef(null);
  const dispatch = useDispatch();
  const xdata = useSelector((state) => state.attributereducer.x);
  const zoomindata = useSelector((state) => state.attributereducer.zoomindata);
  const ydata = useSelector((state) => state.attributereducer.y);
  const errorcode = useSelector((state) => state.attributereducer.errorcode);
  const errormessage = useSelector(
    (state) => state.attributereducer.errormessage
  );
  const zoomtopoint = useSelector(
    (state) => state.attributereducer.zoomtopoint
  );

  const cordinates = useSelector(
    (state) => state.locationapireducer.cordinates
  );
  const ATRcheckbox = useSelector(
    (state) => state.counttypesreducer.ATRcheckbox
  );

  const Bicyclecheckbox = useSelector(
    (state) => state.counttypesreducer.Bicyclecheckbox
  );

  const Pedestrianscheckbox = useSelector(
    (state) => state.counttypesreducer.Pedestrianscheckbox
  );
  const TurningMovementcheckbox = useSelector(
    (state) => state.counttypesreducer.TurningMovementcheckbox
  );
  const SpotSpeedcheckbox = useSelector(
    (state) => state.counttypesreducer.SpotSpeedcheckbox
  );
  const Classificationscheckbox = useSelector(
    (state) => state.counttypesreducer.Classificationscheckbox
  );

  const initialmap = useSelector((state) => state.attributereducer.initialmap);
  useEffect(() => {
    loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/FeatureLayer",
        "esri/Graphic",
        "esri/layers/GraphicsLayer",
        "esri/geometry/Point",
        "esri/widgets/Sketch/SketchViewModel",
        "esri/geometry/geometryEngineAsync",
        "esri/widgets/Print",
      ],
      { css: true }
    ).then(
      ([
        Map,
        MapView,
        FeatureLayer,
        Graphic,
        GraphicsLayer,
        Point,
        SketchViewModel,
        geometryEngineAsync,
      ]) => {
        // Create a map

        const map = new Map({
          basemap: "gray-vector", // You can change the basemap type here
        });

        // Create a view

        const view = new MapView({
          container: mapRef.current,
          map: map,
          center: [-73.95, 40.701],
          zoom: 8,
        });

        const hospitalLegend = {
          type: "simple",
          symbol: {
            type: "picture-marker",
            url:
              "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAP5JREFUOI3Vka2qQkEUhb/RQfwJ/iUPXMFkFEwWo1Gwi81HsPkSwryCb2Az3CI2QdFgsyhYFLEdYXAMJ8joEQ96wz2rzOy12R9rsyV/LBkuYKdzVa4rakGHXRc9HpvfyyXS9wEaWamYbq9HfLkEx4H1Gup1G7JaQaEA8zk0GtBsCj0aGQlCPyWMRr06k4F83nsflc16vXTaqxMJmxGyozwql4Ph0PZSqS+AjgPtdrBkgYDnM2w2tlcuQzL5IXC7hWrV9na7L4CfKKTAwwFiMTge/XtSwun0Fih0qWT2SvEznd5dpeyByeT+HwzQi4WZQUT7Jmy1RPHVKkH1/49yA/00QL6AeXlrAAAAAElFTkSuQmCC",
            width: 10,
            height: 10,
          },
        };
        var hospitals = new FeatureLayer({
          url:
            "https://services5.arcgis.com/GfwWNkhOj9bNBqoJ/arcgis/rest/services/Facilities/FeatureServer/0",
          renderer: hospitalLegend,
          definitionExpression: "FACTYPE = 'HOSPITAL'",
          minScale: 50000,
          maxScale: 0,
        });
        map.add(hospitals);

        const schoolLegend = {
          type: "simple",
          symbol: {
            type: "picture-marker",
            url:
              "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAQ5JREFUOI1jYaAyYCFGkXovwx5OMYb2C7EMe6li4I+3DOacXAy7VVrYb/PL/cw4G8ewnyIDOXgZ/sr6MzD++vhT7cl+hj3qLey3eeR/pp2NZThEloEwwMbPwKAUwMD06uxP9duzGPZpL2TwuxrPsI1sA78+YGB4tIvhFwM/wx7FGIbEs+EMr8hy4Y93DCzXZzH8YuBj2M+jzxB/IZDhJS61xLrwC48+gz4+g0gykJWd4ScxhhFtIClg1EAIUKlneMzAySAI4//+zMCh0sHwBa7gC8O7Oy0MckQbyMzJwKEWx8CNJgznX5/M8IskF1IC6GPg388MjPc2M/zFqesPwz+SDLzTyiBCstPwGUgJAADGekq0pSy7owAAAABJRU5ErkJggg==",
            width: 10,
            height: 10,
          },
        };
        var elementaryschool = new FeatureLayer({
          url:
            "https://services5.arcgis.com/GfwWNkhOj9bNBqoJ/arcgis/rest/services/Facilities/FeatureServer/0",
          renderer: schoolLegend,
          definitionExpression: "FACTYPE = 'ELEMENTARY SCHOOL - PUBLIC'",
          minScale: 50000,
          maxScale: 0,
        });
        map.add(elementaryschool);

        view.ui.add("select-by-polygon", "top-left");
        view.ui.add("select-by-rectangle", "top-left");
        // view.ui.add("select-by-rectangle2", "top-left");

        view.when(() => {
          view.popup.autoOpenEnabled = true;
          // Add widget to the top right corner of the view
          //  view.ui.add(print, "top-right");
        });
        var template = {
          // autocasts as new PopupTemplate()
          title: "{Note}  {Borough}",
          content: [
            {
              // It is also possible to set the fieldInfos outside of the content
              // directly in the popupTemplate. If no fieldInfos is specifically set
              // in the content, it defaults to whatever may be set within the popupTemplate.
              type: "fields",
              fieldInfos: [
                {
                  fieldName: "CountsType",
                  label: "Count Type",
                },
                {
                  fieldName: "Borough",
                  label: "Borough",
                },
                {
                  fieldName: "Direction",
                  label: "Direction",
                },
                {
                  fieldName: "SegmentID",
                  label: "Segment Id",
                },
                {
                  fieldName: "NodeID",
                  label: "Node Id",
                },
              ],
            },
          ],
        };
        view.popup.defaultPopupTemplate = template;

        const graphics = [];
        const totalCounts = data;

        // Using Proj4 for converting X and Y points to Latitude and Longitude
        const nad83 =
          "+proj=lcc +lat_1=41.03333333333333 +lat_2=40.66666666666666 +lat_0=40.16666666666666 +lon_0=-74 +x_0=300000.0000000001 +y_0=0 +ellps=GRS80 +datum=NAD83 +to_meter=0.3048006096012192 +no_defs";
        const wgs84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";

        for (let i = 0; i < totalCounts.length; i++) {
          const location = initialmap
            ? new Point({
                type: "point",
                latitude: initialmap
                  ? proj4(nad83, wgs84, [totalCounts[i].x, totalCounts[i].y])[1]
                  : totalCounts[i].Y,
                longitude: initialmap
                  ? proj4(nad83, wgs84, [totalCounts[i].x, totalCounts[i].y])[0]
                  : totalCounts[i].X,
              })
            : new Point({
                type: "point",
                latitude: totalCounts[i].Y,
                longitude: totalCounts[i].X,
              });

          const dateObjectStart = new Date(totalCounts[i].countStartDate);
          const formattedStartDate =
            `${(dateObjectStart.getMonth() + 1).toString().padStart(2, "0")}/` +
            `${dateObjectStart.getDate().toString().padStart(2, "0")}/` +
            `${dateObjectStart.getFullYear()}`;
          const dateEndObject = new Date(totalCounts[i].countEndDate);
          const formattedEndDate =
            `${(dateEndObject.getMonth() + 1).toString().padStart(2, "0")}/` +
            `${dateEndObject.getDate().toString().padStart(2, "0")}/` +
            `${dateEndObject.getFullYear()}`;

          graphics.push(
            new Graphic({
              geometry: location,
              attributes: {
                OBJECTID: i,
                types: totalCounts[i].types,
                countType: totalCounts[i].countType,
                countRequestId: totalCounts[i].countRequestId,
                siteId: totalCounts[i].siteId,
                countStartDate: formattedStartDate,
                countEndDate: formattedEndDate,
                borough: totalCounts[i].borough,
                direction: totalCounts[i].direction,
                segmentId: totalCounts[i].segmentId,
                nodeId: totalCounts[i].nodeId,
                Note: totalCounts[i].note,
                X: location.longitude,
                Y: location.latitude,
              },
            })
          );
        }

        var countlayer = new FeatureLayer({
          title: "Attributes",
          source: graphics,
          objectIdField: "OBJECTID",
          fields: [
            {
              name: "types",
              alias: "types",
              type: "string",
            },
            {
              name: "countType",
              alias: "countType",
              type: "string",
            },
            {
              name: "countRequestId",
              alias: "countRequestId",
              type: "integer",
            },
            {
              name: "countStartDate",
              alias: "countStartDate",
              type: "string",
            },
            {
              name: "countEndDate",
              alias: "countEndDate",
              type: "string",
            },
            {
              name: "siteId",
              alias: "siteId",
              type: "integer",
            },
            {
              name: "siteId",
              alias: "siteId",
              type: "integer",
            },
            {
              name: "borough",
              alias: "borough",
              type: "string",
            },
            {
              name: "direction",
              alias: "direction",
              type: "string",
            },
            {
              name: "segmentId",
              alias: "segmentId",
              type: "integer",
            },
            {
              name: "nodeId",
              alias: "nodeId",
              type: "integer",
            },

            {
              name: "note",
              alias: "note",
              type: "string",
            },
            {
              name: "X",
              alias: "X",
              type: "double",
            },
            {
              name: "Y",
              alias: "Y",
              type: "double",
            },
          ],

          popupTemplate: template,
        });
        // Define a function to set symbol colors based on checkbox state
        const setSymbolColors = (checkboxState, index, color) => {
          if (checkboxState) {
            return {
              type: "simple-marker",
              size: 8,
              color: color,
              outline: {
                width: 0.5,
                color: "black",
              },
            };
          } else {
            return {
              type: "simple-marker",
              size: 8,
              color: [0, 0, 0, 0],
              outline: {
                width: 0.5,
                color: [0, 0, 0, 0],
              },
            };
          }
        };

        // Define renderer symbols for each checkbox
        const atrSymbol = setSymbolColors(ATRcheckbox, 0, "red");
        const bicycleSymbol = setSymbolColors(Bicyclecheckbox, 1, "yellow");
        const pedestriansSymbol = setSymbolColors(
          Pedestrianscheckbox,
          2,
          "blue"
        );
        const classificationsSymbol = setSymbolColors(
          Classificationscheckbox,
          3,
          "#26ED49"
        );
        const turningMovementSymbol = setSymbolColors(
          TurningMovementcheckbox,
          4,
          "#2596be"
        );
        const spotSpeedSymbol = setSymbolColors(SpotSpeedcheckbox, 5, "pink");

        // Define unique value infos for each checkbox
        const uniqueValueInfos = [
          { value: "ATR", symbol: atrSymbol },
          { value: "Bicycle", symbol: bicycleSymbol },
          { value: "Pedestrian", symbol: pedestriansSymbol },
          { value: "Classification", symbol: classificationsSymbol },
          { value: "Turning Movement", symbol: turningMovementSymbol },
          { value: "Spot Speed", symbol: spotSpeedSymbol },
        ];

        // Set up the count layer renderer
        const countRenderer = {
          type: "unique-value",
          field: "countType",
          defaultSymbol: { type: "simple-marker" },
          uniqueValueInfos: uniqueValueInfos,
        };

        // Apply the renderer to the count layer
        countlayer.renderer = countRenderer;

        if (xdata !== 0 && ydata !== 0) {
          var xcord = parseFloat(xdata);
          var ycord = parseFloat(ydata);
          let geopositions = [];
          geopositions.push(proj4(nad83, wgs84, [xcord, ycord]));
          var pt = new Point({
            latitude: geopositions[0][1],
            longitude: geopositions[0][0],
          });
          // go to the given point
          if (zoomindata) {
            view.goTo({
              target: pt,
              zoom: 18,
            });
          }
        }
        if (
          cordinates &&
          cordinates.XCoordinate !== undefined &&
          cordinates.YCoordinate !== undefined
        ) {
          var xcord = parseFloat(cordinates.XCoordinate);
          var ycord = parseFloat(cordinates.YCoordinate);
          let geopositions = [];
          geopositions.push(proj4(nad83, wgs84, [xcord, ycord]));
          var pt = new Point({
            latitude: geopositions[0][1],
            longitude: geopositions[0][0],
          });
          // go to the given point
          view.goTo({
            target: pt,
            zoom: 18,
          });
        }

        const selectRectangle = document.getElementById("select-by-rectangle");
        const selectPolygon = document.getElementById("select-by-polygon");
        var sketchLayer = new GraphicsLayer();
        map.add(sketchLayer);
        // sketchViewModel creates Rectangle and Polygon Geometry
        const sketchViewModel = new SketchViewModel({
          view: view,
          layer: sketchLayer,
        });
        selectRectangle.addEventListener("click", () => {
          sketchViewModel.create("rectangle");
        });

        selectPolygon.addEventListener("click", () => {
          sketchViewModel.create("polygon");
        });
        sketchViewModel.on("create", async (event) => {
          if (event.state === "complete") {
            // this polygon will be used to query features that intersect it
            const geometries = sketchLayer.graphics.map(function (graphic) {
              return graphic.geometry;
            });
            const queryGeometry = await geometryEngineAsync.union(
              geometries.toArray()
            );
            selectFeatures(queryGeometry);
            //  selectFeatures(queryGeometry);
          }
        });
        function selectFeatures(geometry) {
          if (countlayer) {
            // create a query and set its geometry parameter to the
            // rectangle that was drawn on the view
            const query = {
              geometry: geometry,
              outFields: ["*"],
            };
            // query graphics from the csv layer view. Geometry set for the query
            // can be polygon for point features and only intersecting geometries are returned
            countlayer.queryFeatures(query).then((results) => {
              if (results.features.length === 0) {
              } else {
                var items = [];
                results.features.map((feature) => {
                  items.push(feature.attributes);
                });

                dispatch(initialMapData(false));
                dispatch(updategetAttributesData(items));
                // pass in the query results to the table by calling its selectRows method.
                // This will trigger FeatureTable's selection-change event
                // where we will be setting the feature effect on the csv layer view
              }
            });
          }
        }

        view.map.add(countlayer);
      }
    );
  }, [
    data,
    xdata,
    ydata,
    cordinates,
    ATRcheckbox,
    Bicyclecheckbox,
    Classificationscheckbox,
    Pedestrianscheckbox,
    TurningMovementcheckbox,
    SpotSpeedcheckbox,
    zoomindata,
  ]);

  return (
    <>
      <div
        ref={mapRef}
        className="map"
        aria-label="This is a Traffic information Management System's Count Map"
        role="application"
      >
        <div
          id="select-by-polygon"
          aria-label="Polygon"
          className="esri-widget esri-widget--button esri-widget esri-interactive"
          title="Select features by polygon"
        >
          <span className="esri-icon-polygon"></span>
        </div>
        <div
          id="select-by-rectangle"
          className="esri-widget esri-widget--button esri-widget esri-interactive"
          title="Select features by rectangle"
        >
          <span className="esri-icon-sketch-rectangle"></span>
        </div>

        <ToastContainer />
        {errorcode &&
          toast.info(errormessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {
              dispatch({
                type: DATA_NOT_FOUND1,
                payload: false,
              });
            },
          })}
      </div>
    </>
  );
};

export default DataMap;
