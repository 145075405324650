import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import HomeIcon from "@mui/icons-material/Home";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import "./homepage.scss";
import { useDispatch, useSelector } from "react-redux";
import Datamap from "../../components/datamap/Datamap";
import Datatable from "../../components/datatable/Datatable";
import Search from "../../components/search/Search";
import FilterbyId from "../../components/search/FilterbyId";
import CircularProgress from "@mui/material/CircularProgress";
import {
  initialMapData,
  getSearchById,
  getAttributesData,
  enablefilterbyid,
  enableallfilter,
  updategetAttributesData,
} from "./../../redux/actions/attributeactions";

const Homepage = ({}) => {
  const dispatch = useDispatch();
  const mapData = useSelector((state) => state.attributereducer.items);
  const filterbyidmodal = useSelector(
    (state) => state.attributereducer.filterbyidmodal
  );
  const allfitermodal = useSelector(
    (state) => state.attributereducer.allfitermodal
  );

  const handleCloseFilter = () => {
    dispatch(enablefilterbyid(false));
  };

  const handleClick = () => {
    dispatch(enablefilterbyid(true, false));
  };

  const handleClickAllfilter = () => {
    dispatch(enableallfilter(true));
  };

  const handleClickResetMap = () => {
    dispatch(initialMapData(true, false));
    const countData = localStorage.getItem("countdata");
    // Parse the string representation back to an array
    const dataArray = JSON.parse(countData);
    dispatch(updategetAttributesData(dataArray));
  };

  const handleCloseAllfilter = () => {
    dispatch(enableallfilter(false));
  };

  const handleFilter = (searchData) => {
    dispatch(getSearchById(searchData));
  };

  useEffect(() => {
    dispatch(initialMapData(true, true));
    // Check if "countdata" in localStorage is empty
    const countData = localStorage.getItem("countdata");
    const dataArray = JSON.parse(countData);

    if (dataArray === null || dataArray.length === 0) {
      dispatch(getAttributesData());
      console.log();
    } else {
      dispatch(updategetAttributesData(dataArray));
    }
  }, []);
  const chipStyle = {
    marginTop: 10,
    marginRight: 10,
    fontFamily: "Roboto",
    backgroundColor: "#0d79b3",
  };

  return (
    <div className="home">
      <div className="buttongroups">
        <div className="requestGroup"></div>

        <div className="searchexport">
          <Chip
            label="Reset Map"
            color="info"
            variant="filled"
            className="filterby searchexportbutton"
            size="small"
            onClick={handleClickResetMap}
            style={chipStyle}
          />
          <Chip
            label="Filter By ID"
            color="info"
            variant="filled"
            className="filterby searchexportbutton"
            size="small"
            onClick={handleClick}
            style={chipStyle}
          />
          <Chip
            label="All Filters"
            color="info"
            variant="filled"
            className="allfiters searchexportbutton"
            size="small"
            onClick={handleClickAllfilter}
            style={chipStyle}
          />
        </div>
      </div>

      <Breadcrumbs
        style={{ marginLeft: 10, marginBottom: 5, marginTop: 10 }}
        aria-label="breadcrumb"
      >
        {[
          { label: "Home", icon: <HomeIcon className="homebreadcrumb" /> },
          { label: "Data", icon: <WhatshotIcon className="homebreadcrumb" /> },
        ].map(({ label, icon }, index) => (
          <RouterLink
            key={index}
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            className="breadcrumbhome"
            to={index === 0 ? "/homepage" : "/homepage"}
          >
            {icon && React.cloneElement(icon, { sx: { mr: 0.3 } })}
            <span style={{ fontSize: 13 }}>{label}</span>
          </RouterLink>
        ))}
      </Breadcrumbs>

      {mapData != null && mapData.length > 0 ? (
        <Datamap data={mapData} />
      ) : (
        <div className="spinner-overlay" style={{ display: "flex" }}>
          <div className="spinner-container">
            <CircularProgress />
          </div>
        </div>
      )}

      {mapData != null && mapData.length > 0 && <Datatable data={mapData} />}
      {allfitermodal && (
        <Search
          handleClickAllfilter={handleClickAllfilter}
          handlecloseAllfilter={handleCloseAllfilter}
        />
      )}
      {filterbyidmodal && (
        <FilterbyId
          handleFilter={handleFilter}
          handleclosefilter={handleCloseFilter}
        />
      )}
    </div>
  );
};

export default Homepage;
