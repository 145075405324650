import { combineReducers } from "redux";

// reducer import
import Attributereducer from "./attributereducer";
import CountTypesreducer from "./counttypesreducer";
import MapLayersreducer from "./maplayersreducer";
import CountRequestByIDreducer from "./countrequestbyid";
import RequestDetailByIdreducer from "./requestdetailbyid";
import Locationreducer from "./locationreducer";
import Errorreducer from "./errorreducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  attributereducer: Attributereducer,
  counttypesreducer: CountTypesreducer,
  mapLayersreducer: MapLayersreducer,
  countrequestbyidreducer: CountRequestByIDreducer,
  requestdetailbyidreducer: RequestDetailByIdreducer,
  locationapireducer: Locationreducer,
  errorreducer: Errorreducer,
});

export default reducer;
